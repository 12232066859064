import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function GoHome() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { replace: "true" });
  }, []);

  return <div className="fix-white"></div>;
}
