import { React } from "react";
import Footer2 from "./Footer2";

import HeaderLower from "./HeaderLower";
import "./Button.css";

import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Privacy Policy";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div className="white-box thank">
          <h1>PRIVACY STATEMENT </h1>

          <div className="p-policy text-start">
            <h4 className="text-uppercase">
              {process.env.REACT_APP_API_SITE_TITLE} PRIVACY STATEMENT
            </h4>
            <b>Site Security Features</b>
            <p>
              {process.env.REACT_APP_API_SITE_TITLE} realizes how important
              security is to our clients and to their participants, so we’ve
              taken a number of steps to enhance the protection of personal or
              confidential information sent to or from BSSTSI or in accessing
              information resident at BSSTSI . This includes username, password,
              and any debit card information. First, we require unique usernames
              and password establishment that are not easily determined by
              someone other than the intended participant. This requirement
              protects personal information and access to personal earnings that
              are available for redemption.
            </p>
            <b>SSL Technology</b>
            <p>
              BSSTSI requires that a “secure session” is established, using
              Secure Socket Layer (SSL) technology. This is done anytime a
              participant supplies BSSTSI with personal or confidential
              information in one of the secure areas of a website.
            </p>
            <p>
              SSL technology creates a private conversation that only the
              participant’s computer and BSSTSI can understand. The SSL
              technology encodes information as it is being sent over the
              Internet between the participant’s computer and ER’s system
              helping to ensure that the transmitted information remains
              confidential.
            </p>
            <p>
              The use of SSL requires two components: and SSL-compatible browser
              and a Web server to perform “key-exchange” that establishes a
              secure connection to BSSTSI web servers.
            </p>
            <b>Browser Compliance</b>
            <p>
              Participants and client will need a browser with SSL capabilities.
              Examples of SSL browsers include, Microsoft’s Internet Explorer,
              Firefox, Chrome and Safari. If a participant does not have a
              browser with SSL capabilities, the participant can download an SSL
              browser from the above mentioned SSL browser list and will be
              required in order to securely access personal or confidential
              information via the Internet. BSSTSI codes sites to current
              browser version minus 1. BSSTSI recommends the use of the latest
              browser versions available. Accessing secure online areas requires
              SSL capable browsers due to security concerns.
            </p>
            <b>ISP Compliance</b>
            <p>
              Nearly all Internet Service Providers (ISPs) automatically enable
              the SSL session described above. If a participant or a client
              contact uses their company's internal connection to access the
              Internet they may find they cannot access the BSSTSI secure pages
              with an SSL browser described above, the company may be blocking
              access via a "firewall." The company’s Internet access systems
              administrator would have to be contacted for further details on
              Internet access.
            </p>
            <b>Cookies</b>
            <p>
              A participant or client must have enabled cookies on their browser
              in order to access confidential information. If they have chosen
              to disable cookies on their browser, they will not be able to
              access confidential information.
            </p>
            <b>Third Party Use</b>
            <p>
              BSSTSI does not sell, trade or rent personal information to third
              parties. We do, however, share your shipping information with our
              suppliers for the sole purpose of delivering your redemption
              items.
            </p>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default PrivacyPolicy;
